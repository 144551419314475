import React from 'react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import NProgress from 'nprogress';
import { ChakraProvider } from '@chakra-ui/react';
import initLogrocket from '@/src/lib/initLogrocket';
import '@/src/lib/initSentry';
import theme from '@/src/shared/config/theme';
import 'antd/lib/style/index';
import { Provider } from 'next-auth/client';
import StackedLayout from '@/src/shared/components/StackedLayout';
import AuthenticatedNavbar from '@/src/components/AuthenticatedNavbar';

/** Initialize LogRocket on client only, with correct environment */
initLogrocket();

/** NextJS Router vents for integrating progress bar across pages */
Router.events.on('routeChangeStart', () => {
  NProgress.configure({ showSpinner: false });
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

// TODO: componentDidCatch, logrocket, sentry
function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>Matternet App Suite</title>
      </Head>
      <Provider session={pageProps.session}>
        <ChakraProvider theme={theme}>
          <StackedLayout>
            <AuthenticatedNavbar />
            <Component {...pageProps} />
          </StackedLayout>
          {/* <Box bg="gray.100"></Box> */}
        </ChakraProvider>
      </Provider>
    </>
  );
}

export default App;
