import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { NavbarProps } from './types';
import { NavbarMenu } from './NavbarMenu';

/**
 * ## Navbar is a responsive app bar with configurable menus.
 *
 * Use Navbar as a part of your app layout to provide page context
 * as well as providing user actions through the configurable menus.
 * When provided a user's name, the initials will be used to generate a
 * unique text-based Avatar - alternatively this will be used as a fallback
 * if an image is additionally supplied. Currently, the color is not configurable.
 *
 *
 * To configure the Navbar's menu, a `menuConfig` must be provided. These consist
 * of MenuGroups with optional titles, which each contain `n` MenuItems - with
 * each MenuItem allowing for a label and click handler. Dividers are automatically created.
 *
 */
const Navbar = ({ title, menuConfig, onTitleClick }: NavbarProps) => {
  const navTitleProps = onTitleClick
    ? {
        onClick: onTitleClick,
        style: {
          cursor: 'pointer',
        },
      }
    : {};

  return (
    <Box
      pos="fixed"
      as="nav"
      top="0"
      zIndex={4}
      bg="white"
      left="0"
      right="0"
      borderBottomWidth="1px"
      width="full"
      height="4rem"
      shadow="sm"
    >
      <Flex
        height="100%"
        size="100%"
        px="6"
        align="center"
        justify="space-between"
      >
        <Flex align="center" backgroundColor="red" mr={5}>
          <Box
            as="a"
            display="block"
            aria-label={title || 'Homepage'}
            {...navTitleProps}
          >
            <Text fontSize="lg" color="black">
              {title}
            </Text>
          </Box>
        </Flex>
        <Flex
          flex={{ sm: '1', md: 'none' }}
          ml={5}
          align="center"
          color="gray.500"
          justify="flex-end"
        >
          <NavbarMenu menuConfig={menuConfig} />
        </Flex>
      </Flex>
    </Box>
  );
};

export default Navbar;
