import { theme as chakraTheme, extendTheme } from '@chakra-ui/react';

/**
 * More info about configuring default themes here:
 * https://github.com/chakra-ui/chakra-ui/tree/master/packages/chakra-ui/src/theme
 * https://system-ui.com/theme/
 */

/**
 * Colors
 * Sourced from TailwindUI:
 * https://cdn.jsdelivr.net/npm/@tailwindcss/ui@latest/dist/tailwind-ui.css
 *
 * How TailwindUI colors have changed over Tailwind:
 * https://www.notion.so/How-Tailwind-UI-Extends-Tailwind-2f74104b886c47b3b7a24a65f1d24935
 */

const colors = {
  gray: {
    50: '#f9fafb',
    100: '#f4f5f7',
    200: '#e5e7eb',
    300: '#d2d6dc',
    400: '#9fa6b2',
    500: '#6b7280',
    600: '#4b5563',
    700: '#374151',
    800: '#252f3f',
    900: '#161e2e',
  },
  indigo: {
    50: '#f0f5ff',
    100: '#e5edff',
    200: '#cddbfe',
    300: '#b4c6fc',
    400: '#8da2fb',
    500: '#6875f5',
    600: '#5850ec',
    700: '#5145cd',
    800: '#42389d',
    900: '#362f78',
  },
};

const fonts = {
  heading: `'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif`,
  body: `'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif`,
  mono: `'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace;`,
};

const letterSpacings = { 'tracking-wider': chakraTheme.letterSpacings.wider };

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: 'gray.100',
      },
    },
  },
  colors,
  fonts,
  letterSpacings,
});

export default theme;
