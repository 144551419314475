import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import Sentry from '@/src/lib/initSentry';

const initLogrocket = () => {
  if (typeof window !== 'undefined') {
    if (process.env.REVISION_ENVIRONMENT === 'staging') {
      // TODO: Can we get LogRocket to reset this App ID or rename to 'apps-staging'?
      LogRocket.init('matternet/apps-staging-npqab');
      setupLogRocketReact(LogRocket);
    }

    if (process.env.REVISION_ENVIRONMENT === 'preview') {
      LogRocket.init('matternet/apps-preview');
      setupLogRocketReact(LogRocket);
    }

    if (process.env.REVISION_ENVIRONMENT === 'edge') {
      LogRocket.init('matternet/apps-edge');
      setupLogRocketReact(LogRocket);
    }

    if (process.env.REVISION_ENVIRONMENT === 'production') {
      LogRocket.init('matternet/apps-production');
      setupLogRocketReact(LogRocket);
    }

    LogRocket.getSessionURL((sessionURL) => {
      Sentry.configureScope((scope) => {
        scope.setExtra('sessionURL', sessionURL);
      });
    });
  }
};

export default initLogrocket;
