import React, { ComponentProps, useMemo, useEffect } from 'react';
import { useSession, signOut } from 'next-auth/client';
import LogRocket from 'logrocket';
import Navbar from '@/src/shared/components/Navbar';
import { CALLBACK_URL } from '@/src/shared/config/constants';
import { useRouter } from 'next/router';

type NavbarMenuConfig = ComponentProps<typeof Navbar>['menuConfig'];

const AuthenticatedNavbar = () => {
  const router = useRouter();
  const [session] = useSession();
  const email = session?.user?.email;
  const id = session?.account?.id;

  useEffect(() => {
    if (email && id) {
      LogRocket.identify(id, {
        email,
      });
    }
  }, [email, id]);

  const handleTitleClick = () => {
    router.push('/');
  };

  const menuConfig: NavbarMenuConfig = useMemo(() => {
    if (session) {
      return {
        name: session.user.email,
        avatarSrc: session.user.image,
        menuGroups: [
          {
            menuItems: [
              {
                label: 'Logout',
                onClick: () => {
                  signOut({
                    callbackUrl: CALLBACK_URL,
                  });
                },
              },
            ],
          },
        ],
      };
    }

    return undefined;
  }, [session]);

  return (
    <Navbar
      title="Matternet"
      menuConfig={menuConfig}
      onTitleClick={handleTitleClick}
    />
  );
};

export default AuthenticatedNavbar;
