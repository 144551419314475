import React from 'react';
import {
  Menu,
  MenuButton,
  Avatar,
  MenuGroup,
  MenuItem,
  MenuDivider,
  MenuList,
} from '@chakra-ui/react';
import { useUIDSeed } from 'react-uid';
import { NavbarMenuConfig, NavbarMenuGroup } from './types';

export const NavbarMenuList = ({
  menuGroups,
}: {
  menuGroups: NavbarMenuGroup[];
}) => {
  const seed = useUIDSeed();

  const MenuGroups = menuGroups.map((menuGroup, index) => {
    return (
      <React.Fragment key={seed(menuGroup)}>
        <MenuGroup key={menuGroup.title} title={menuGroup.title}>
          {menuGroup.menuItems.map(({ label, onClick }) => (
            <MenuItem key={label} onClick={onClick}>
              {label}
            </MenuItem>
          ))}
        </MenuGroup>
        {index < menuGroups.length - 1 ? <MenuDivider /> : null}
      </React.Fragment>
    );
  });

  return <MenuList>{MenuGroups}</MenuList>;
};

export const NavbarMenu = ({
  menuConfig,
}: {
  menuConfig: NavbarMenuConfig | undefined;
}) => {
  if (!menuConfig) return null;

  const { name, menuGroups, avatarSrc } = menuConfig;

  const userAvatar = avatarSrc || 'https://bit.ly/broken-link';

  return (
    <Menu>
      <MenuButton>
        <Avatar size="sm" name={name} src={userAvatar} />
      </MenuButton>
      <NavbarMenuList menuGroups={menuGroups} />
    </Menu>
  );
};

export default NavbarMenu;
